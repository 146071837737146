





















import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

const MOLECULES = {
  1: '$\\ce{SO3^2-}$',
  2: '$\\ce{NCCH2CH2CHO}$',
  3: '$\\ce{(CH3)2H2N^+}$',
};

export default defineComponent({
  name: 'UofTQuiz1Question2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      LSattachments: [] as File[],
    };
  },
  computed: {
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    molecule(): string {
      return MOLECULES[this.version as keyof typeof MOLECULES];
    },
    attachments(): File[] {
      return [...this.LSattachments];
    },
  },
});
